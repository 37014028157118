@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
html,
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

:root {
    --color-uno: #8e3f65;
    --color-dos: #73738d;
    --color-tres: #72a5ae;
    --color-cuatro: #98e9d0;
    --color-cinco: #d8ffcc;
    --color-uno-trans: #8e3f650c;
    --color-dos-trans: #73738d0c;
    --color-tres-trans: #72a5ae0c;
    --color-cuatro-trans: #98e9d00c;
    --color-cinco-trans: #d8ffcc0c;
    --color-text-chinese: #151515;
    --color-text-vampire: #0a0a0a;
}

body {
    font-family: 'IBM Plex Mono', monospace;
    background: #151515;
    background: var(--color-text-chinese);
    color: #98e9d0;
    color: var(--color-cuatro);
    line-height: 1.6;        
}

ul{
    list-style: none;    
}

a{
    color: #151515;
    color: var(--color-text-chinese);
    text-decoration: none;
}

h1, h2 {
    font-weight: 300;
    line-height: 1.2;
}

p {
    margin: 10px 0;
}

img {
  width: 100%;  
}


